<template>
  <footer class="ui-footer">
    <slot />
  </footer>
</template>

<script>
export default {
  name: 'UiFooter',
};
</script>

<style scoped lang="scss">
.ui-footer {
}
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M21.334 10.6665C23.4557 10.6665 25.4905 11.5094 26.9908 13.0096C28.4911 14.5099 29.334 16.5448 29.334 18.6665V27.9998H24.0007V18.6665C24.0007 17.9593 23.7197 17.281 23.2196 16.7809C22.7195 16.2808 22.0412 15.9998 21.334 15.9998C20.6267 15.9998 19.9485 16.2808 19.4484 16.7809C18.9483 17.281 18.6673 17.9593 18.6673 18.6665V27.9998H13.334V18.6665C13.334 16.5448 14.1768 14.5099 15.6771 13.0096C17.1774 11.5094 19.2123 10.6665 21.334 10.6665Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.99935 12H2.66602V28H7.99935V12Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.33268 7.99984C6.80544 7.99984 7.99935 6.80593 7.99935 5.33317C7.99935 3.86041 6.80544 2.6665 5.33268 2.6665C3.85992 2.6665 2.66602 3.86041 2.66602 5.33317C2.66602 6.80593 3.85992 7.99984 5.33268 7.99984Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

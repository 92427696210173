<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M16 4C9.37294 4 4 9.40537 4 16.0724C4 22.0972 8.38764 27.0909 14.1254 28V19.5629H11.0771V16.0724H14.1254V13.4126C14.1254 10.3845 15.9172 8.71476 18.6571 8.71476C19.97 8.71476 21.3453 8.9502 21.3453 8.9502V11.9191H19.8284C18.3402 11.9191 17.8746 12.8512 17.8746 13.8062V16.07H21.2001L20.6685 19.5605H17.8746V27.9976C23.6124 27.0933 28 22.0984 28 16.0724C28 9.40537 22.6271 4 16 4V4Z"
      stroke="white"
      stroke-width="1.5"
    />
  </svg>
</template>

<template>
  <div
    class="social-media row wrap justify-start content-start"
    itemscope
    itemtype="https://schema.org/SocialMediaPosting"
  >
    <a
      v-if="props.isNeedEmail"
      class="link q-mr-sm"
      href="mailto:hello@dreamflat.design"
      >hello@dreamflat.design</a
    >
    <a
      v-if="!props.isDflat"
      href="https://www.instagram.com/dreamflat.design/"
      class="link q-mr-sm"
      target="_blank"
      rel="me"
      itemprop="sameAs"
    >
      <SvgSocialInsBSvg />
    </a>
    <a
      v-if="!props.isDflat"
      href="https://www.facebook.com/profile.php?id=100090565989165"
      class="link q-mr-sm"
      target="_blank"
      rel="me"
      itemprop="sameAs"
    >
      <SvgSocialFacebookBSvg />
    </a>
    <a
      v-if="!props.isDflat"
      href="https://www.linkedin.com/company/93650643/"
      class="link q-mr-sm"
      target="_blank"
      rel="me"
      itemprop="sameAs"
    >
      <SvgSocialLinkedinBSvg />
    </a>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    isNeedEmail: boolean;
    isDflat: boolean;
  }>(),
  {},
);
</script>

<style lang="scss" scoped>
.social-media {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  a {
    display: flex;
    justify-content: center;
  }
}
</style>
